import { News, NewsCategory, Web, cdn } from 'data';
import Image from 'next/image';

type Props = {
	aNew: News;
	web: Web;
};

export const NewsCardComponent = ({ aNew, web }: Props) => {
	const category = aNew.category as NewsCategory;

	return (
		<div className="p-4 md:w-1/3">
			<div className="h-full rounded-xl shadow-cla-blue bg-white shadow-lg overflow-hidden">
				<Image
					src={cdn(aNew.image)}
					className="lg:h-48 md:h-36 w-full object-cover object-center scale-110 transition-all duration-400 hover:scale-100"
					alt={`${web.name} - ${aNew.name}`}
					title={`${web.name} - ${aNew.name}`}
					width={400}
					height={250}
				/>
				<div className="p-6">
					<h4 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">
						{category.name}
					</h4>
					<h3 className="title-font text-lg font-medium text-gray-600 mb-3">{aNew.name}</h3>
					<p className="leading-relaxed mb-3">{aNew.shortDescription}</p>
					<div className="flex items-center flex-wrap ">
						<a
							href={`/noticias/${category.code}/${aNew.code}`}
							className="bg-yellow-400 hover:scale-105 drop-shadow-md shadow-cla-blue px-4 py-1 rounded-lg">
							Leer más
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
